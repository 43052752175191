import axios from 'axios';
import { createStore } from 'vuex';
import { ISettings } from '../entities/settings';
import router from '../router';
import {
  getSettings, getToken, getTokenFromSignInProviderRedirect, getTokenPayload, isTokenExpired,
  SignInProvider,
} from '../services/steamcord';

type TokensObject = { [slug: string]: string };

export interface IState {
  loading: boolean;
  tokens: TokensObject;
  settings?: ISettings;
  slug?: string;
}

function getTokensFromLocalStorage(): TokensObject {
  const value = localStorage.getItem('tokens');
  return value ? JSON.parse(value) : {};
}

export default createStore<IState>({
  state: {
    loading: true,
    settings: undefined,
    tokens: getTokensFromLocalStorage(),
  },
  mutations: {
    SET_LOADING(state, { loading }) {
      state.loading = loading;
    },
    SET_SETTINGS(state, { settings }: { settings: ISettings }) {
      state.settings = settings;
    },
    SET_SLUG(state, slug: string) {
      state.slug = slug;
      axios.defaults.headers.common.Authorization = `Bearer ${state.tokens[slug]}`;
    },
    SET_TOKEN(state, { slug, token }: { slug: string, token: string }) {
      state.tokens[slug] = token;
      localStorage.setItem('tokens', JSON.stringify(state.tokens));
    },
    DELETE_TOKEN(state, slug: string) {
      delete state.tokens[slug];
      localStorage.removeItem('token');
    },
  },
  actions: {
    async setSettingsFromSlug({ commit }, slug: string) {
      const settings = await getSettings(slug);
      if (settings) {
        commit('SET_SETTINGS', { settings });
      }
    },
    async setTokenFromSlug({ commit }, slug: string) {
      const token = await getToken(slug);
      if (token) {
        commit('SET_TOKEN', { slug, token });
        commit('SET_SLUG', slug);
      } else {
        commit('DELETE_TOKEN', slug);
        router.push({ name: 'Not Found' });
      }
    },
    async setTokenFromRedirect({ commit }, { provider, params, slug }:
      { provider: SignInProvider, params: URLSearchParams, slug: string }) {
      commit('SET_TOKEN',
        { slug, token: await getTokenFromSignInProviderRedirect(provider, params) });
    },
    deleteToken({ commit }) {
      commit('DELETE_TOKEN');
      window.location.reload();
    },
  },
  getters: {
    isTokenExpired({ tokens, slug }) {
      if (slug && tokens[slug]) {
        const payload = getTokenPayload(tokens[slug]);
        return isTokenExpired(payload);
      }

      return true;
    },
    player({ tokens, slug }) {
      if (slug && tokens[slug]) {
        return getTokenPayload(tokens[slug]).player;
      }

      return undefined;
    },
    token({ tokens, slug }) {
      return tokens && slug ? tokens[slug] : undefined;
    },
  },
});
