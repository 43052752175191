
import { defineComponent } from 'vue';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import { getSignInURI, SignInProvider } from '../services/steamcord';

import Account from '../components/Account.vue';
import ExternalAnchor from '../components/ExternalAnchor.vue';
import Modal from '../components/Modal.vue';
import SignInButton from '../components/SignInButton.vue';
import SignInCard from '../components/SignInCard.vue';
import Tooltip from '../components/Tooltip.vue';

export default defineComponent({
  components: {
    Account,
    ExternalAnchor,
    Modal,
    SignInButton,
    SignInCard,
    Tooltip,
  },
  computed: {
    ...mapGetters(['player', 'isTokenExpired']),
    ...mapState(['loading', 'settings', 'slug', 'token']),
    discordGuildInviteUri() {
      if (this.settings.discordGuildInviteUri.startsWith('http')) {
        return this.settings.discordGuildInviteUri;
      }

      return `https://${this.settings.discordGuildInviteUri}`;
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    ...mapActions(['setSettingsFromSlug', 'setTokenFromSlug']),
    ...mapMutations(['SET_LOADING', 'SET_SLUG']),
    async redirect(provider: SignInProvider, params: { [key: string]: string }): Promise<void> {
      const uri = await getSignInURI(provider, this.slug, params);
      if (uri) {
        window.location.href = uri;
      }
    },
  },
  async mounted() {
    const slug = this.$route.params.slug ?? this.overrideSlug;
    await this.SET_SLUG(slug);

    if (this.isTokenExpired) {
      await this.setTokenFromSlug(slug);
    }

    await this.setSettingsFromSlug(slug);
    await this.SET_LOADING(false);
  },
  props: {
    overrideSlug: String,
  },
});
