import axios from 'axios';
import router from './router';
import store from './store';

export function setAxiosDefaults(): void {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;

  axios.interceptors.request.use((config) => {
    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${store.getters.token}`;
    }

    return config;
  });

  axios.interceptors.response.use((res) => res, (err) => {
    if (err.response?.status === 400) {
      router.push({ name: 'Bad Request' });
    }

    if (err.response?.status === 401) {
      store.dispatch('deleteToken');
    }

    if (err.response?.status === 429) {
      router.push({ name: 'Rate Limit' });
    }

    if (err.response?.status >= 500) {
      router.push({ name: 'Internal Server Error' });
    }

    return Promise.reject(err);
  });
}
