import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_anchor = _resolveComponent("external-anchor")!

  return (_openBlock(), _createBlock(_component_external_anchor, {
    class: _normalizeClass(_ctx.providerClassMap[_ctx.provider]),
    "show-icon": false
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}