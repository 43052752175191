import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { IPlayer } from '../entities/player';
import { ISettings } from '../entities/settings';

export async function getSettings(slug: string): Promise<ISettings | undefined> {
  try {
    const { data: settings } = await axios.get<ISettings>(`/settings/${slug}`);
    return settings;
  } catch {
    return undefined;
  }
}

export async function getToken(slug: string): Promise<string | undefined> {
  try {
    const { data: token } = await axios.post<string>(`/auth/${slug}`);
    return token;
  } catch {
    return undefined;
  }
}

export type SignInProvider = 'discord' | 'steam';

const signInProviderPathMap = {
  discord: '/discord',
  steam: '/steam',
};

export async function getSignInURI(
  provider: SignInProvider,
  slug: string,
  params: { [key: string]: string } = {},
): Promise<string> {
  const queryString = new URLSearchParams(params).toString();

  const { data: uri } = await axios.get<string>(
    `${signInProviderPathMap[provider]}?slug=${slug}&${queryString}`,
  );

  return uri;
}

export async function getTokenFromSignInProviderRedirect(
  provider: SignInProvider,
  params: URLSearchParams,
): Promise<string> {
  const providerPath = signInProviderPathMap[provider];
  const redirectUri = `${providerPath}/token?${params}`;
  const { data: token } = await axios.post<string>(redirectUri);

  return token;
}

export async function unlinkAccount(provider: SignInProvider, id: string): Promise<string> {
  const { data: token } = await axios.delete<string>(`${signInProviderPathMap[provider]}/${id}`);
  return token;
}

export interface ITokenPaylod {
  exp: number;
  orgID: number;
  player: IPlayer;
}

export function getTokenPayload(token: string): ITokenPaylod {
  return jwtDecode(token);
}

export function isTokenExpired(payload: ITokenPaylod): boolean {
  return payload.exp * 1000 <= Date.now();
}
