
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import AppHeader from './components/AppHeader.vue';
import ExternalAnchor from './components/ExternalAnchor.vue';
import Spinner from './components/Spinner.vue';

export default defineComponent({
  components: {
    AppHeader,
    Spinner,
    ExternalAnchor,
  },
  computed: {
    ...mapState(['loading', 'settings']),
  },
});
