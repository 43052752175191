
import * as Sentry from '@sentry/vue';
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  computed: mapGetters(['isTokenExpired']),
  methods: {
    ...mapActions(['setTokenFromRedirect']),
    ...mapMutations(['SET_SLUG']),
  },
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  async created() {
    const params = new URLSearchParams(window.location.search);

    let slug: string | undefined;

    try {
      const state = params.get('state');
      if (state) {
        slug = JSON.parse(state).slug;
      }
    } catch {
      //
    }

    slug ??= (params.get('slug') ?? undefined);

    if (!slug) {
      Sentry.captureMessage(`No slug found in redirect with URL ${window.location.href}`);
      this.$router.push({ name: 'Bad Request' });
      return;
    }

    this.SET_SLUG(slug);

    if (!this.isTokenExpired && !params.get('error')) {
      await this.setTokenFromRedirect({ provider: this.provider, params, slug });
    }

    this.$router.push(`/${slug}`);
  },
});
