import {
  faArrowRight, faCheck, faExternalLink, faInfoCircle, faSpinnerThird, faTimes, faUnlink,
} from '@fortawesome/pro-regular-svg-icons';
import { faDiscord, faSteam } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { App as Application, createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import App from './App.vue';
import { setAxiosDefaults } from './axios';
import router from './router';
import store from './store';

library.add(faArrowRight, faCheck, faDiscord, faExternalLink, faInfoCircle, faSpinnerThird, faSteam,
  faTimes, faUnlink);

const app: Application = createApp(App)
  .component('fa-icon', FontAwesomeIcon)
  .use(store)
  .use(router);

app.mount('#app');

setAxiosDefaults();

Sentry.init({
  app,
  dsn: 'https://8b5b18e1dec64c74b05566b6f1da21d0@o1113759.ingest.sentry.io/6144531',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'steamcord.link', /^\//],
    }),
  ],
  tracesSampleRate: 0.5,
});
