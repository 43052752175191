
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import ExternalAnchor from '../components/ExternalAnchor.vue';

export default defineComponent({
  components: { ExternalAnchor },
  methods: mapMutations(['SET_LOADING']),
  mounted() {
    this.SET_LOADING(false);
  },
  props: {
    status: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
});
