
import { defineComponent, PropType } from 'vue';
import { SignInProvider } from '../services/steamcord';
import ExternalAnchor from './ExternalAnchor.vue';

const providerClassMap: { [provider: string]: string } = {
  discord: 'sign-in-button--discord',
  steam: 'sign-in-button--steam',
};

const providerNameMap: { [provider: string]: string } = {
  discord: 'Discord',
  steam: 'Steam',
};

export default defineComponent({
  components: {
    ExternalAnchor,
  },
  data() {
    return {
      providerClassMap,
      providerNameMap,
    };
  },
  props: {
    provider: {
      type: String as PropType<SignInProvider>,
      required: true,
    },
  },
});
