import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import HomeRedirect from '@/views/HomeRedirect.vue';
import Redirect from '@/views/Redirect.vue';
import Error from '@/views/Error.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: HomeRedirect,
  },
  {
    path: '/discord/redirect',
    component: Redirect,
    props: { provider: 'discord' },
    meta: { authorize: true },
  },
  {
    path: '/steam/redirect',
    component: Redirect,
    props: { provider: 'steam' },
    meta: { authorize: true },
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import('@/views/custom/Demo.vue'),
  },
  {
    path: '/project-nova',
    name: 'Project Nova',
    component: () => import('@/views/custom/ProjectNova.vue'),
    meta: { showHeader: true },
  },
  {
    path: '/:slug',
    name: 'Home',
    component: Home,
    meta: { showHeader: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: Error,
    props: {
      status: 404,
      title: 'Page Not Found',
      text: 'We couldn\'t find the page you\'re looking for.',
    },
  },
  {
    path: '/400',
    name: 'Bad Request',
    component: Error,
    props: {
      status: 400,
      title: 'Bad Request',
      text: 'Please try again later.',
    },
  },
  {
    path: '/429',
    name: 'Rate Limit',
    component: Error,
    props: {
      status: 429,
      title: 'You\'re Doing That Too Fast',
      text: 'Please try again later.',
    },
  },
  {
    path: '/500',
    name: 'Internal Server Error',
    component: Error,
    props: {
      status: 500,
      title: 'Internal Server Error',
      text: 'Please try again later.',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
