
import { defineComponent, PropType } from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { SignInProvider, unlinkAccount } from '../services/steamcord';
import Modal from './Modal.vue';
import Tooltip from './Tooltip.vue';

export default defineComponent({
  components: {
    Modal,
    Tooltip,
  },
  computed: {
    ...mapGetters(['player']),
    ...mapState(['slug']),
  },
  data() {
    return {
      loading: false,
      showModal: false,
    };
  },
  methods: {
    ...mapMutations(['SET_TOKEN']),
    async unlink() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      const token = await unlinkAccount(this.provider, this.id);
      this.loading = false;

      await this.SET_TOKEN({ token, slug: this.slug });
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    provider: {
      type: String as PropType<SignInProvider>,
      required: true,
    },
    showRewards: {
      type: Boolean,
      required: true,
    },
  },
});
