
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    hidden: {
      type: Boolean,
      required: true,
    },
  },
})
export default class Spinner extends Vue {}
